import React, { useEffect, useState } from "react";
import { FaGlobe, FaThumbsUp, FaDollarSign, FaWifi } from "react-icons/fa";
import { FiSmile } from "react-icons/fi";
import PlanTabs from "./Tabs";

const ServiceSection = () => {
  const [isMobile, setIsMobile] = useState(false);
  console.log(isMobile, window.innerWidth, "Mobile");

  useEffect(() => {
    if (window.innerWidth <= 600) {
      setIsMobile(true);
    }
  });

  const serviceData = [
    {
      title: "Fiber Internet",
      description:
        "We provide best in class GPON technology with best speeds upto 300Mbps",
      icon: FaGlobe,
    },
    {
      title: "Wireless Internet",
      description: "Living in a rural area? We got you.",
      icon: FaWifi,
    },
    {
      title: "High Speed Broadband ",
      description: "We provide high speed broadband upto 300Mbps plans",
      icon: FiSmile,
    },
    {
      title: "Free Installation",
      description: "Just pay for monthly rentals, get free to use CPE",
      icon: FaDollarSign,
    },
  ];

  return (
    <>
      <div className="my-5 max-w-7xl mx-auto " style={{ fontFamily: "Inter" }}>
        {/* 
        <div className="grid grid-cols-4 gap-4 md:grid-cols-2 sm:grid-cols-1 lg:grid-cols-4">
          {serviceData?.map((service) => {
            return (
              <div className="rounded-xl card shadow-md p-5 flex flex-col items-start justify-start w-50">
                <div className="bg-red-600 px-3 py-3 rounded-full">
                  <service.icon className="h-6 w-6 text-white " />
                </div>
                <p className="font-medium my-5 text-xl">{service?.title}</p>
                <p className="font-medium text-gray-700">
                  {service?.description}
                </p>
              </div>
            );
          })}
        </div> */}
        <div className="mx-3 text-3xl tracking-wide font-medium sm:text-xl md:text-2xl">
          Quick Plans Overview
        </div>
        <div className="mx-3 px-1 h-1 bg-red-500 w-20 mb-5"></div>

        <PlanTabs />
        <span className="text-gray-400 text-xs mx-4">
          T&C Apply* Plans may change based on feasiblity
        </span>
      </div>
    </>
  );
};

export default ServiceSection;
