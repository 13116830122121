import React from "react";

const OffersTnC = () => {
  return (
    <div className="offers-tnc">
      <div className="text-2xl text-center">Terms & Conditions</div>
    </div>
  );
};

export default OffersTnC;
