import React, { useEffect, useState } from "react";
import Entertainment from "../../Assets/entertainment.jpg";
import EpicOn from "../../Assets/epicOn.png";
import Logo from "../../Assets/MaxxOffers.svg";
import Shemaroo from "../../Assets/shemaroo.png";
import Silver from "../../Assets/silverPlan.jpg";
import Voot from "../../Assets/voot.png";
import Work from "../../Assets/workGold.jpg";
import Zee5 from "../../Assets/zee5.png";
import Footer from "../../Components/Footer";
import HeaderNav from "../../Components/HeaderNav";
import Features from "../Home/Components/Features";
import OttTabs from "./components/OttTabs";
import PlanTypography from "./components/PlanTypography";

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2, itemsToScroll: 2 },
  { width: 768, itemsToShow: 3 },
  { width: 1200, itemsToShow: 4 },
];

const OttPackages = () => {
  const plansDataOTT = [
    {
      hero: Silver,
      plan: "Silver",
      fup: "Unlimited",
      speed: "50Mbps",
      price: "799",
      ott: [
        {
          platform: "Epic-on",
          img: "https://assets.airtel.in/static-assets/safo/cat/images/prime.svg",
        },
        {
          platform: "Shemaroo",
          img: "https://assets.airtel.in/static-assets/safo/cat/images/HOTSTAR_MOBILE.png",
        },
        {
          platform: "Playbox Tv",
          img: "https://assets.airtel.in/static-assets/thanks/images/propositions/home/WYNKP.png",
        },
      ],
    },
    {
      hero: Work,
      plan: "Platinum",
      fup: "Unlimited",
      speed: "20Mbps",
      price: "999",
      ott: [
        {
          platform: "Epic-on",
          img: EpicOn,
        },
        {
          platform: "Shemaroo",
          img: Shemaroo,
        },
        {
          platform: "Playbox Tv",
          img: Shemaroo,
        },
      ],
      ottRow: [
        {
          platform: "Voot",
          img: Voot,
        },
        {
          platform: "Zee5",
          img: Zee5,
        },
      ],
    },
    {
      hero: Entertainment,
      plan: "Gold",
      fup: "Unlimited",
      speed: "30Mbps",
      price: "1299",
      ott: [
        {
          platform: "Epic-on",
          img: "https://assets.airtel.in/static-assets/safo/cat/images/prime.svg",
        },
        {
          platform: "Shemaroo",
          img: "https://assets.airtel.in/static-assets/safo/cat/images/HOTSTAR_MOBILE.png",
        },
        {
          platform: "Playbox Tv",
          img: "https://assets.airtel.in/static-assets/thanks/images/propositions/home/WYNKP.png",
        },
      ],
    },
  ];

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth <= 800) {
      setIsMobile(true);
    }
  });

  return (
    <div>
      <HeaderNav id="top" />
      {isMobile ? (
        <div className="bg-blue-500 mb-5 py-3 px-5 flex flex-col stripPlans text-white">
          <span className="font-bold">Unlock more than 1 Gbps Speed</span>
          <span className="text-sm font-light">
            Introducing Broadband Plans with OTT Subscription
          </span>
        </div>
      ) : (
        <div className="stripPlansWeb">
          <div className="max-w-7xl mx-auto sm:px-6 mb-5 py-7 px-48 flex flex-row items-center justify-between text-white">
            <span>
              <img src={Logo} style={{ height: 50 }} />
            </span>
            <span className="font-medium mt-2  text-2xl ml-5">
              Introducing Broadband Plans with OTT Subscription
            </span>
            <div className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-red-600 ">
              Full Masti
            </div>
          </div>
        </div>
      )}
      <div className=" max-w-7xl mx-auto">
        <OttTabs />
      </div>
      {/* <PlanCard plansData={plansDataOTT} /> */}
      <div className="m-10 max-w-7xl mx-auto px-4 sm:px-6">
        <div className="text-sm font-light ">T&C apply.</div>
      </div>
      <Features />
      <div className="m-10 max-w-7xl mx-auto px-2">
        <PlanTypography />
      </div>
      <Footer />
    </div>
  );
};

export default OttPackages;
