import React from "react";
import { Button } from "@mui/material";
import ContactModal from "./ContactModal";
import discount from "../Assets/discount.svg";

const OfferHero = () => {
  return (
    <div style={{ backgroundColor: "#fff" }}>
      <div
        style={{
          fontFamily: "Inter",
          backgroundColor: "#fff",
        }}
        className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between"
      >
        <div className="flex flex-col items-center md:flex-row">
          <div className="py-5">
            {/* <img src={Logo} alt="logo" style={{ height: 50 }} /> */}
            <div>
              <h2
                className="text-black font-light w-3/4 md:w-3/4 lg:w-3/34 xl:w-3/4"
                style={{ fontSize: 30 }}
              >
                Simply pay
                <span className="font-extrabold text-black"> ₹1000*</span>
                to get New Connection with one Month Subscription
              </h2>
            </div>
            <div className="mt-5">
              <span>
                <p
                  className="text-black w-3/4 md:w-3/4 lg:w-3/4 xl:w-3/4"
                  style={{ fontSize: 20 }}
                >
                  *A refundable security deposit of Rs.2000 for Wi-Fi ONT levied
                  additionally.
                  <br /> *No Installation charges on the Fiber connection.
                </p>
              </span>
              <span>
                <p
                  className="text-gray-600 w-full mt-10"
                  style={{ fontSize: 15 }}
                >
                  *T&C Apply
                </p>
              </span>
            </div>
            <div className="mt-10">
              <div className="w-full md:w-7/12 xl:w-3/4 flex items-center justify-center px-4 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-red-600 cursor-pointer">
                <ContactModal offer={true} />
              </div>
            </div>
          </div>
          <div className="w-full flex items-center justify-center">
            <img src={discount} alt="newConnect" style={{ width: "500px" }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OfferHero;
