import React from "react";
import Footer from "../../Components/Footer";
import HeaderNav from "../../Components/HeaderNav";
import PrivacyContent from "./components/PrivacyContent";

const privacy = [
  "It is important for Maxx Fibernet that you to know how we treat information about you that we may receive through this website www.maxxfibernet.in (website).",
];
const infoUse = [
  "Market research, including statistical analysis of user behavior which we may disclose to third parties in aggregated form.",
  "In compliance with any requirement of law.",
  "To send you periodic communications about our features, products, services, events and special offers.",
  "We do not share User Information with third parties.",
];

const Privacy = () => {
  return (
    <>
      <div>
        <HeaderNav />
        <div className="m-10 max-w-7xl mx-auto px-4 sm:px-6">
          <h1 className="text-4xl font-semibold text-red-600">
            Privacy Policy
          </h1>
          <p className="px-2">- Last Updated : 01 Jan 2022</p>
        </div>
        <PrivacyContent
          data={privacy}
          para={true}
          parag="This web server collects the domain names, not the e-mail addresses of visitors. We may however, store personal information that you give us, for a specific purpose. This includes details such as your name, address, telephone, VC number, account number and/ or e-mail address. This Privacy Policy is applicable all the personal information that you give us (User Information) via this website. We want you to know that your personal details shall remain private and secure with us. We are not bound to reply to you when you leave your personal details. This website is not intended for persons under 13 years of age. We do not knowingly solicit or collect personal information from or about children, and we do not market our products and/or services to children."
          sub={infoUse}
        />
      </div>
      <Footer />
    </>
  );
};

export default Privacy;
