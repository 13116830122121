import React, { useEffect, useState } from "react";
import { MdSupportAgent, MdWifi } from "react-icons/md";
import { RiArrowUpDownLine } from "react-icons/ri";
import Basic from "../../Assets/basicMobile.jpg";
import Entertainment from "../../Assets/entertainment.jpg";
import Logo from "../../Assets/MaxxOffers.svg";
import Silver from "../../Assets/silverPlan.jpg";
import Work from "../../Assets/workGold.jpg";
import Footer from "../../Components/Footer";
import HeaderNav from "../../Components/HeaderNav";
import PlanCard from "../../Components/PlanCard";
import Features from "../Home/Components/Features";
import PlanTypography from "./components/PlanTypography";

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2, itemsToScroll: 2 },
  { width: 768, itemsToShow: 3 },
  { width: 1200, itemsToShow: 4 },
];

const FiberPlans = () => {
  const plansData = [
    {
      hero: Basic,
      plan: "Basic",
      fup: "Unlimited",
      speed: "50Mbps",
      price: "499",
      benefits: [
        {
          platform: "Priority Support",
          img: MdSupportAgent,
        },
        {
          platform: "Wi-Fi Calling",
          img: MdWifi,
        },
        {
          platform: "Twin Speed",
          img: RiArrowUpDownLine,
        },
      ],
    },
    {
      hero: Silver,

      plan: "Silver",
      fup: "Unlimited",
      speed: "120Mbps",
      price: "799",
      benefits: [
        {
          platform: "Priority Support",
          img: MdSupportAgent,
        },
        {
          platform: "Wi-Fi Calling",
          img: MdWifi,
        },
        {
          platform: "Twin Speed",
          img: RiArrowUpDownLine,
        },
      ],
    },
    {
      hero: Work,
      plan: "Gold",
      fup: "Unlimited",
      speed: "200Mbps",
      price: "999",
      benefits: [
        {
          platform: "Priority Support",
          img: MdSupportAgent,
        },
        {
          platform: "Wi-Fi Calling",
          img: MdWifi,
        },
        {
          platform: "Twin Speed",
          img: RiArrowUpDownLine,
        },
      ],
    },
    {
      hero: Entertainment,

      plan: "Platinum",
      fup: "Unlimited",
      speed: "300Mbps",
      price: "1399",
      benefits: [
        {
          platform: "Priority Support",
          img: MdSupportAgent,
        },
        {
          platform: "Wi-Fi Calling",
          img: MdWifi,
        },
        {
          platform: "Twin Speed",
          img: RiArrowUpDownLine,
        },
      ],
    },
  ];

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth <= 800) {
      setIsMobile(true);
    }
  });

  return (
    <div>
      <HeaderNav id="top" />
      {isMobile ? (
        <div className="bg-blue-500 mb-5 py-3 px-5 flex flex-col stripPlans text-white">
          <span className="font-bold">
            High Speed Optical Fiber Plans at Affordable Prices
          </span>
          <span className="text-sm font-light">
            Enjoy Unlimited Entertainment
          </span>
        </div>
      ) : (
        <div className="stripPlansWeb">
          <div className="max-w-7xl mx-auto sm:px-6 mb-5 py-7 px-48 flex flex-row items-center justify-between text-white">
            <span>
              <img src={Logo} style={{ height: 50 }} />
            </span>
            <span className="font-medium mt-2  text-2xl ml-5">
              High Speed Optical Fiber Plans at Affordable Prices
            </span>
            <div className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-red-600 hover:bg-red-700">
              Fiber Internet
            </div>
          </div>
        </div>
      )}

      <PlanCard plansData={plansData} />

      <div className="m-10 max-w-7xl mx-auto px-4 sm:px-6">
        <div className="text-sm font-light ">
          T&C Apply* Plans may change based on feasiblity
        </div>
      </div>
      <Features />
      <div className="m-10 max-w-7xl mx-auto px-2">
        <PlanTypography />
      </div>
      <Footer />
    </div>
  );
};

export default FiberPlans;
