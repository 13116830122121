import React from "react";
import OfferBannerImg from "../Assets/offerImg.png";
import Logo from "../Assets/MaxxFibernetLogoLight.svg";
import { Link } from "react-router-dom";

const OfferBanner = () => {
  return (
    <div style={{ backgroundColor: "#101010" }}>
      <div
        style={{
          fontFamily: "Inter",
          backgroundColor: "#101010",
        }}
        className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between"
      >
        <div className="flex flex-col items-center md:flex-row">
          <div className="py-5">
            <img src={Logo} alt="logo" style={{ height: 50 }} />
            <div className="mt-10">
              <h2
                className="text-white font-light w-3/4 md:w-2/3 lg:w-2/3 xl:w-2/3"
                style={{ fontSize: 30 }}
              >
                Simply pay <span className="font-extrabold">₹1000*</span> to get
                New Connection with one Month Subscription
              </h2>
            </div>
            <div className="mt-5">
              <span>
                <p
                  className="text-gray-400  w-5/6 md:w-2/3 lg:w-2/3 xl:w-2/3"
                  style={{ fontSize: 18 }}
                >
                  *A refundable security deposit of Rs.2000 for Wi-Fi ONT levied
                  additionally.
                  <br />
                  *No Installation charges on the Fiber connection.
                </p>
              </span>
            </div>
            <div className="mt-10">
              <Link
                to="/offers"
                className="w-full md:w-7/12 xl:w-3/4 flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-red-600 hover:bg-red-700"
              >
                Explore the offer
              </Link>
            </div>
          </div>
          <div className="w-full flex items-center justify-center">
            <img src={OfferBannerImg} alt="Offer" style={{ height: 400 }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OfferBanner;
