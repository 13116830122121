import * as React from "react";
import { styled } from "@mui/system";
import TabsUnstyled from "@mui/base/TabsUnstyled";
import TabsListUnstyled from "@mui/base/TabsListUnstyled";
import TabPanelUnstyled from "@mui/base/TabPanelUnstyled";
import { buttonUnstyledClasses } from "@mui/base/ButtonUnstyled";
import TabUnstyled, { tabUnstyledClasses } from "@mui/base/TabUnstyled";
import { GoThumbsup } from "react-icons/go";
import { MdSupportAgent, MdWifi } from "react-icons/md";
import { GiRadioTower, GiWifiRouter } from "react-icons/gi";
import { AiOutlineCloudSync } from "react-icons/ai";
import { RiArrowUpDownLine } from "react-icons/ri";
import { SiNetflix } from "react-icons/si";
import Work from "../Assets/workGold.jpg";
import Entertainment from "../Assets/entertainment.jpg";
import Basic from "../Assets/basicMobile.jpg";
import Silver from "../Assets/silverPlan.jpg";
import Gaming from "../Assets/proGaming.jpg";
import EpicOn from "../Assets/epicOn.png";
import Shemaroo from "../Assets/shemaroo.png";
import PlayBox from "../Assets/playBox.png";
import Voot from "../Assets/voot.png";
import Zee5 from "../Assets/zee5.png";
import SonyLiv from "../Assets/sonyLiv.png";
import Prime from "../Assets/prime.png";
import ContactModal from "./ContactModal";

const plansData = [
  {
    plan: "Basic",
    fup: "Unlimited",
    speed: "50Mbps",
    price: "499",
    benefits: [
      {
        platform: "Priority Support",
        img: MdSupportAgent,
      },
      {
        platform: "Wi-Fi Calling",
        img: MdWifi,
      },
      {
        platform: "Twin Speed",
        img: RiArrowUpDownLine,
      },
    ],
  },
  {
    plan: "Silver",
    fup: "Unlimited",
    speed: "120Mbps",
    price: "799",
    benefits: [
      {
        platform: "Priority Support",
        img: MdSupportAgent,
      },
      {
        platform: "Wi-Fi Calling",
        img: MdWifi,
      },
      {
        platform: "Twin Speed",
        img: RiArrowUpDownLine,
      },
    ],
  },
  {
    plan: "Gold",
    fup: "Unlimited",
    speed: "200Mbps",
    price: "999",
    benefits: [
      {
        platform: "Priority Support",
        img: MdSupportAgent,
      },
      {
        platform: "Wi-Fi Calling",
        img: MdWifi,
      },
      {
        platform: "Twin Speed",
        img: RiArrowUpDownLine,
      },
    ],
  },
  {
    plan: "Platinum",
    fup: "Unlimited",
    speed: "300Mbps",
    price: "1399",
    benefits: [
      {
        platform: "Priority Support",
        img: MdSupportAgent,
      },
      {
        platform: "Wi-Fi Calling",
        img: MdWifi,
      },
      {
        platform: "Twin Speed",
        img: RiArrowUpDownLine,
      },
    ],
  },
];

const plansDataWireless = [
  {
    hero: Basic,
    plan: "Basic",
    fup: "240GB",
    speed: "10Mbps",
    price: "589",
    benefits: [
      {
        platform: "Priority Support",
        img: MdSupportAgent,
      },
      {
        platform: "Wi-Fi Calling",
        img: MdWifi,
      },
      {
        platform: "Twin Speed",
        img: RiArrowUpDownLine,
      },
    ],
  },
  {
    hero: Silver,
    plan: "Silver",
    fup: "Unlimited",
    speed: "15Mbps",
    price: "799",
    benefits: [
      {
        platform: "Priority Support",
        img: MdSupportAgent,
      },
      {
        platform: "Wi-Fi Calling",
        img: MdWifi,
      },
      {
        platform: "Twin Speed",
        img: RiArrowUpDownLine,
      },
    ],
  },
  {
    hero: Work,
    plan: "Gold",
    fup: "Unlimited",
    speed: "20Mbps",
    price: "999",
    benefits: [
      {
        platform: "Priority Support",
        img: MdSupportAgent,
      },
      {
        platform: "Wi-Fi Calling",
        img: MdWifi,
      },
      {
        platform: "Twin Speed",
        img: RiArrowUpDownLine,
      },
    ],
  },
  {
    hero: Entertainment,
    plan: "Platinum",
    fup: "Unlimited",
    speed: "30Mbps",
    price: "1299",
    benefits: [
      {
        platform: "Priority Support",
        img: MdSupportAgent,
      },
      {
        platform: "Wi-Fi Calling",
        img: MdWifi,
      },
      {
        platform: "Twin Speed",
        img: RiArrowUpDownLine,
      },
    ],
  },
  {
    hero: Gaming,

    plan: "Diamond",
    fup: "Unlimited",
    speed: "50Mbps",
    price: "1699",
    benefits: [
      {
        platform: "Priority Support",
        img: MdSupportAgent,
      },
      {
        platform: "Wi-Fi Calling",
        img: MdWifi,
      },
      {
        platform: "Twin Speed",
        img: RiArrowUpDownLine,
      },
    ],
  },
];

// const plansDataPremium = [
//   {
//     hero: Basic,
//     plan: "Base OTT",
//     fup: "Unlimited",
//     speed: "50Mbps",
//     price: "1099",
//     ott: [
//       {
//         platform: "Epic-on",
//         img: EpicOn,
//       },
//       {
//         platform: "Shemaroo",
//         img: Shemaroo,
//       },
//       {
//         platform: "Playbox Tv",
//         img: PlayBox,
//       },
//     ],
//   },
//   {
//     hero: Silver,
//     plan: "Plus",
//     fup: "Unlimited",
//     speed: "50Mbps",
//     price: "1199",
//     ott: [
//       {
//         platform: "Epic-on",
//         img: EpicOn,
//       },
//       {
//         platform: "Shemaroo",
//         img: Shemaroo,
//       },
//       {
//         platform: "Playbox Tv",
//         img: PlayBox,
//       },
//     ],
//     ottRow: [
//       {
//         platform: "Voot",
//         img: Voot,
//       },
//       {
//         platform: "Zee5",
//         img: Zee5,
//       },
//     ],
//   },
//   {
//     hero: Work,
//     plan: "Premium",
//     fup: "Unlimited",
//     speed: "50Mbps",
//     price: "1299",
//     ott: [
//       {
//         platform: "Epic-on",
//         img: EpicOn,
//       },
//       {
//         platform: "Shemaroo",
//         img: Shemaroo,
//       },
//       {
//         platform: "Playbox Tv",
//         img: PlayBox,
//       },
//     ],
//     ottRow: [
//       {
//         platform: "Voot",
//         img: Voot,
//       },
//       {
//         platform: "Zee5",
//         img: Zee5,
//       },
//       {
//         platform: "Sony LIV",
//         img: SonyLiv,
//       },
//     ],
//   },
//   {
//     hero: Entertainment,
//     plan: "Platinum",
//     fup: "Unlimited",
//     speed: "50Mbps",
//     price: "1449",
//     ott: [
//       {
//         platform: "Epic-on",
//         img: EpicOn,
//       },
//       {
//         platform: "Shemaroo",
//         img: Shemaroo,
//       },
//       {
//         platform: "Playbox Tv",
//         img: PlayBox,
//       },
//     ],
//     ottRow: [
//       {
//         platform: "Voot",
//         img: Voot,
//       },
//       {
//         platform: "Zee5",
//         img: Zee5,
//       },
//       {
//         platform: "Sony LIV",
//         img: SonyLiv,
//       },
//       {
//         platform: "Amazon Prime",
//         img: Prime,
//       },
//     ],
//   },
// ];

const plansDataPremium = [
  {
    hero: Entertainment,
    plan: "Base",
    fup: "Unlimited",
    speed: "100Mbps",
    price: "949",
    ott: [
      {
        platform: "Epic-on",
        img: EpicOn,
      },
      {
        platform: "Shemaroo",
        img: Shemaroo,
      },
      {
        platform: "Playbox Tv",
        img: PlayBox,
      },
    ],
  },
  {
    hero: Entertainment,
    plan: "Plus",
    fup: "Unlimited",
    speed: "100Mbps",
    price: "1049",
    ott: [
      {
        platform: "Epic-on",
        img: EpicOn,
      },
      {
        platform: "Shemaroo",
        img: Shemaroo,
      },
      {
        platform: "Playbox Tv",
        img: PlayBox,
      },
    ],
    ottRow: [
      {
        platform: "Voot",
        img: Voot,
      },
      {
        platform: "Zee5",
        img: Zee5,
      },
    ],
  },
  {
    hero: Entertainment,
    plan: "Premium",
    fup: "Unlimited",
    speed: "100Mbps",
    price: "1149",
    ott: [
      {
        platform: "Epic-on",
        img: EpicOn,
      },
      {
        platform: "Shemaroo",
        img: Shemaroo,
      },
      {
        platform: "Playbox Tv",
        img: PlayBox,
      },
    ],
    ottRow: [
      {
        platform: "Voot",
        img: Voot,
      },
      {
        platform: "Zee5",
        img: Zee5,
      },
      {
        platform: "Sony LIV",
        img: SonyLiv,
      },
    ],
  },
  {
    hero: Entertainment,
    plan: "Platinum",
    fup: "Unlimited",
    speed: "100Mbps",
    price: "1249",
    ott: [
      {
        platform: "Epic-on",
        img: EpicOn,
      },
      {
        platform: "Shemaroo",
        img: Shemaroo,
      },
      {
        platform: "Playbox Tv",
        img: PlayBox,
      },
    ],
    ottRow: [
      {
        platform: "Voot",
        img: Voot,
      },
      {
        platform: "Zee5",
        img: Zee5,
      },
      {
        platform: "Sony LIV",
        img: SonyLiv,
      },
      {
        platform: "Amazon Prime",
        img: Prime,
      },
    ],
  },
];

const red = {
  50: "#ffebee",
  100: "#ffcdd2",
  200: "#ef9a9a",
  300: "#e57373",
  400: "#ef5350",
  500: "#f44336",
  600: "#e53935",
  700: "#d32f2f",
  800: "#c62828",
  900: "#b71c1c",
};

const Tab = styled(TabUnstyled)`
  font-family: Inter;
  color: white;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: regular;
  background-color: transparent;
  width: 100%;
  padding: 6px 16px;
  margin: 6px 6px;
  border: none;
  border-radius: 5px;
  display: flex;
  justify-content: center;

  &:hover {
    background-color: ${red[400]};
  }

  &.${buttonUnstyledClasses.focusVisible} {
    color: #fff;
    outline: none;
    background-color: ${red[200]};
  }

  &.${tabUnstyledClasses.selected} {
    background-color: ${red[50]};
    color: ${red[600]};
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TabPanel = styled(TabPanelUnstyled)`
  width: 100%;
  font-family: Inter;
  font-size: 0.875rem;
  margin-left: 10px;
`;

const TabsList = styled(TabsListUnstyled)`
  background-color: ${red[500]};
  border-radius: 8px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
`;

export default function PlanTabs() {
  return (
    <TabsUnstyled defaultValue={0}>
      <TabsList className="mx-3">
        <Tab>Fiber</Tab>
        <Tab>AirFiber</Tab>
        <Tab>OTT</Tab>
      </TabsList>
      <TabPanel value={0} className="mx-5">
        <div className="flex flex-row overflow-x-scroll plansScroll py-3">
          {plansData?.map((item) => {
            return (
              <div
                style={{ width: 350 }}
                className=" shadow-lg rounded-lg mr-3 py-2"
              >
                <div className="flex flex-row items-center justify-between border-l-4 px-3 border-l-red-600">
                  <div className="flex flex-col">
                    <span className="font-semibold">{item?.plan}</span>
                    <span className="font-bold">{`₹${item?.price}/month`}</span>
                  </div>
                  <div>
                    {/* <a
                      href="#"
                      className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-xs font-medium text-white bg-red-600 hover:bg-red-700"
                    >
                      Buy Now
                    </a> */}
                    <ContactModal home={true} />
                  </div>
                </div>
                <div className="flex flex-col px-2 mt-4 text-sm">
                  <table>
                    <tbody>
                      <tr className="text-sm text-gray-600">
                        <td>
                          <span>{`Data :`}</span>
                        </td>
                        <td>
                          <span className="ml-1">{` ${item?.fup} Internet`}</span>
                        </td>
                      </tr>
                      <tr className="text-sm text-gray-500">
                        <td>
                          <span>{`Speed :`}</span>
                        </td>
                        <td>
                          <span className="ml-1 font-bold">{` ${item?.speed} `}</span>
                        </td>
                      </tr>
                      <tr className="text-sm text-gray-500">
                        <td>
                          <span>Tech :</span>
                        </td>
                        <td>
                          <span className="ml-1">GPON</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="px-3 mt-4 flex flex-row items-center justify-between">
                  {item?.benefits?.map((ott) => {
                    return (
                      <div className="ott-container mx-1 flex flex-col justify-center items-center">
                        <ott.img color="#e40000" size={30} />
                        <div className="ott-title text-center mt-1 text-xs text-gray-400">
                          {ott.platform}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </TabPanel>
      <TabPanel value={1} className="mx-5">
        <div className="flex flex-row overflow-x-scroll plansScroll py-3">
          {plansDataWireless?.map((item) => {
            return (
              <div
                style={{ width: 350 }}
                className=" shadow-lg rounded-lg py-2 mr-3"
              >
                <div className="flex flex-row items-center justify-between border-l-4 px-3 border-l-red-600">
                  <div className="flex flex-col">
                    <span className="font-semibold">{item?.plan}</span>
                    <span className="font-bold">{`₹${item?.price}/month`}</span>
                  </div>
                  <div>
                    <ContactModal home={true} />
                  </div>
                </div>
                <div className="flex flex-col px-2 mt-4 text-sm">
                  <table>
                    <tbody>
                      <tr className="text-sm text-gray-600">
                        <td>
                          <span>{`Data :`}</span>
                        </td>
                        <td>
                          <span className="ml-1">{` ${item?.fup} Internet`}</span>
                        </td>
                      </tr>
                      <tr className="text-sm text-gray-500">
                        <td>
                          <span>{`Speed :`}</span>
                        </td>
                        <td>
                          <span className="ml-1 font-bold">{` ${item?.speed} `}</span>
                        </td>
                      </tr>
                      <tr className="text-sm text-gray-500">
                        <td>
                          <span>Latency :</span>
                        </td>
                        <td>
                          <span className="ml-1">Upto 1ms*</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="px-3 mt-4 flex flex-row items-center justify-between">
                  {item?.benefits?.map((ott) => {
                    return (
                      <div className="ott-container mx-1 flex flex-col justify-center items-center">
                        <ott.img color="#dc2626" size={30} />
                        <div className="ott-title text-xs text-center mt-1 text-gray-400">
                          {ott.platform}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </TabPanel>
      <TabPanel value={2} className="mx-5">
        <div className="flex flex-row overflow-x-scroll plansScroll py-3">
          {plansDataPremium?.map((item) => {
            return (
              <div
                style={{ width: 350 }}
                className=" shadow-lg rounded-lg mr-3 py-2"
              >
                <div className="flex flex-row items-center justify-between border-l-4 px-3 border-l-red-600">
                  <div className="flex flex-col">
                    <span className="font-semibold">{item?.plan}</span>
                    <span className="font-bold">{`₹${item?.price}/month`}</span>
                  </div>
                  <div>
                    <ContactModal home={true} />
                  </div>
                </div>
                <div className="flex flex-col px-2 mt-4 text-sm">
                  <table>
                    <tbody>
                      <tr className="text-sm text-gray-600">
                        <td>
                          <span>{`Data :`}</span>
                        </td>
                        <td>
                          <span className="ml-1">{` ${item?.fup} Internet`}</span>
                        </td>
                      </tr>
                      <tr className="text-sm text-gray-500">
                        <td>
                          <span>{`Speed :`}</span>
                        </td>
                        <td>
                          <span className="ml-1 font-bold">{` ${item?.speed} `}</span>
                        </td>
                      </tr>
                      <tr className="text-sm text-gray-500">
                        <td>
                          <span>OTT:</span>
                        </td>
                        <td>
                          <span className="ml-1">Included</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="px-3 mt-4 flex flex-row items-center justify-between">
                  {item?.ott?.map((ott) => {
                    return (
                      <div className="ott-container mx-1 flex flex-col justify-center items-center">
                        <img src={ott.img} style={{ height: 30 }} />
                        <div className="ott-title text-center mt-1 text-xs text-gray-500">
                          {ott.platform}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="px-3 mt-4 flex flex-row  items-center">
                  {item?.ottRow?.map((ott) => {
                    return (
                      <div className="ott-container mx-1 flex flex-col justify-center items-center">
                        <img src={ott.img} style={{ height: 30 }} />
                        <div className="ott-title text-center mt-1 text-xs text-gray-400">
                          {ott.platform}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </TabPanel>
    </TabsUnstyled>
  );
}
