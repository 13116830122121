import React from "react";
import ContactModal from "../../Components/ContactModal";
import HeaderNav from "../../Components/HeaderNav";
import PartnerImg from "../../Assets/partnersImg.jpg";
import Footer from "../../Components/Footer";
import PlanTypography from "../Plans/components/PlanTypography";
import PartnerContact from "./PartnerContact";

const PartnerProgram = () => {
  return (
    <div>
      <HeaderNav />
      <div style={{ backgroundColor: "#fff" }}>
        <div
          style={{
            fontFamily: "Inter",
            backgroundColor: "#fff",
          }}
          className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between"
        >
          <div className="flex flex-col items-center md:flex-row">
            <div>
              {/* <img src={Logo} alt="logo" style={{ height: 50 }} /> */}
              <div>
                <h1 className="text-4xl font-semibold text-red-600">
                  Partners Programs
                </h1>
              </div>
              <div className="mt-5">
                <span>
                  <p
                    className="text-black w-full md:w-3/4 lg:w-3/4 xl:w-2/3 mb-3"
                    style={{ fontSize: 20 }}
                  >
                    Maxx Fibernet has been providing the best business
                    opportunities for Wireless & Fiber Internet operators. Our
                    customer base has been growing at an incredibly fast rate
                    and our revenue model ensures that all our partners make
                    adequate profits, irrespective of price fluctuations in the
                    market.
                  </p>
                </span>
              </div>
            </div>
            <div className="w-full flex items-center justify-center">
              <img
                src={PartnerImg}
                style={{ borderRadius: "50%", height: "100%", width: "100%" }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mb-5">
        <PartnerContact />
      </div>
      <Footer />
    </div>
  );
};

export default PartnerProgram;
