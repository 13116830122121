import React, { useEffect, useState } from "react";
import AppBanner from "../../Components/AppBanner";
import EntertainmentBanner from "../../Components/EntertainmentBanner";
import Footer from "../../Components/Footer";
import HeaderNav from "../../Components/HeaderNav";
import HeroSlider from "../../Components/HeroSlider";
import OfferBanner from "../../Components/OfferBanner";
import ServiceSection from "../../Components/ServiceSection";
import CallToAction from "../../Components/CallToAction";
import Features from "./Components/Features";
import PlanTabs from "../../Components/Tabs";
import { Link } from "react-router-dom";

const Home = () => {
  const [isMobile, setIsMobile] = useState(false);
  console.log(isMobile, window.innerWidth, "Mobile");

  useEffect(() => {
    if (window.innerWidth <= 800) {
      setIsMobile(true);
    }
  });

  return (
    <div>
      <HeaderNav />
      {isMobile && (
        <div className="px-2 py-3 bg-red-500 flex justify-center">
          <a
            href="https://portal.maxxfibernet.in/"
            className=" whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-red-600 hover:bg-red-700"
          >
            Pay Bill
          </a>
          <div className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-red-600 hover:bg-red-700">
            <Link to="/plans/fiber">Plans</Link>
          </div>
          <div className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-red-600 hover:bg-red-700">
            <Link to="/offers">Offers</Link>
          </div>
        </div>
      )}
      {/* <HeroSlider /> */}
      <ServiceSection />
      <OfferBanner />
      <EntertainmentBanner />
      <CallToAction />
      <Features />
      {/* <AppBanner /> */}
      <Footer />
    </div>
  );
};

export default Home;
