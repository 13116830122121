import React from "react";
import { GoThumbsup } from "react-icons/go";
import { MdSupportAgent, MdWifi } from "react-icons/md";
import { GiRadioTower, GiWifiRouter } from "react-icons/gi";
import { AiOutlineCloudSync } from "react-icons/ai";
import { RiArrowUpDownLine } from "react-icons/ri";
import { SiPrime } from "react-icons/si";
const Features = () => {
  const serviceData = [
    {
      title: "Wi-Fi Calling",
      icon: MdWifi,
    },
    {
      title: "OTT Included",
      icon: SiPrime,
    },

    {
      title: "1 Gbps Unlimited Speed",
      icon: AiOutlineCloudSync,
    },
    {
      title: "Priority Support",
      icon: MdSupportAgent,
    },
  ];
  const serviceDataSecond = [
    {
      title: "Auto Adjusting Upload/Download Speed",
      icon: RiArrowUpDownLine,
    },
    {
      title: "Auto Troubleshooting Routers",
      icon: GiWifiRouter,
    },
    {
      title: "99.9% Uptime",
      icon: GoThumbsup,
    },
    {
      title: "Proactive Network Monitoring",
      icon: GiRadioTower,
    },
  ];
  return (
    <div
      className="m-10 max-w-7xl mx-auto px-4 sm:px-6"
      style={{ fontFamily: "Inter" }}
    >
      <div className="grid grid-cols-4  gap-4 md:grid-cols-4 sm:grid-cols-2 lg:grid-cols-4">
        {serviceData?.map((service) => {
          return (
            <div className="rounded-xl items-center card flex flex-col text-center justify-start w-50">
              <div className=" px-3 py-3 rounded-full">
                <service.icon color="#e40000" size={48} />
              </div>
              <p className="font-medium my-5 text-md">{service?.title}</p>
            </div>
          );
        })}
      </div>
      <div className="grid grid-cols-4  gap-4 mt-4 md:grid-cols-4 sm:grid-cols-2 lg:grid-cols-4">
        {serviceDataSecond?.map((service) => {
          return (
            <div className="rounded-xl items-center card flex flex-col text-center justify-start w-50">
              <div className=" px-3 py-3 rounded-full">
                <service.icon color="#e40000" size={48} />
              </div>
              <p className="font-medium my-5 text-md px-2">{service?.title}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Features;
