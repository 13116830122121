import "./App.css";
import { React, useLayoutEffect } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Home from "./Screens/Home/Home";
import FiberPlans from "./Screens/Plans/FiberPlans";
import OttPackages from "./Screens/Plans/OttPackages";
import AirnetPlans from "./Screens/Plans/AirnetPlans";
import Offers from "./Screens/Offers/Offers";
import Terms from "./Screens/Terms/Terms";
import Privacy from "./Screens/PrivacyPolicy/Privacy";
import LeasedLine from "./Screens/LeasedLine/LeasedLine";
import PartnerProgram from "./Screens/PartnerProgram/PartnerProgram";
import Contact from "./Screens/Contact/Contact";

const App = () => {
  const Wrapper = ({ children }) => {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children;
  };

  return (
    <div style={{ backgroundColor: "#F7F7F7" }}>
      <BrowserRouter>
        <Wrapper>
          <div>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/offers" element={<Offers />} />
              <Route path="/plans/fiber" element={<FiberPlans />} />
              <Route path="/plans/ott" element={<OttPackages />} />
              <Route path="/plans/wireless" element={<AirnetPlans />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/ill" element={<LeasedLine />} />
              <Route path="/partner" element={<PartnerProgram />} />
              <Route path="/contact" element={<Contact />} />
            </Routes>
          </div>
        </Wrapper>
      </BrowserRouter>
    </div>
  );
};

export default App;
