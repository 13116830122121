import React from "react";
import { FiFastForward } from "react-icons/fi";

const ILLHero = () => {
  return (
    <div
      style={{
        fontFamily: "Inter",
      }}
      className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between"
    >
      <div className="flex flex-col items-center md:flex-row">
        <div className="py-5">
          <div className="text-4xl font-semibold text-red-600">
            Dedicated Internet Leased Line Services
          </div>
          <div className="mt-5">
            <span>
              <p
                className="text-gray-600  w-5/6 md:w-2/3 lg:w-2/3 xl:w-2/3"
                style={{ fontSize: 18 }}
              >
                Maxx Fibernet offers a complete range of connectivity solutions
                and services such as High-Speed Managed Internet Leased Line,
                Unmanaged Internet Leased Line and Bandwidth on Demand Services.
              </p>
            </span>
            <div className="list text-gray-500">
              <div className="list-item mt-5">
                Flexible bandwidth – we can offer guaranteed availability for
                1MB and in multiples of 2 MBPS.
              </div>
              <div className="list-item my-2">
                Rapid installation of business-critical communication links.
              </div>
              <div className="list-item my-2">
                Guaranteed fix times combined with high availability.
              </div>
              <div className="list-item my-2">
                Uptime guaranty of 98.5%+ through OFC cables.
              </div>
              <div className="list-item my-2">
                Free 24 hour helpdesk support.
              </div>
            </div>
          </div>
        </div>
        <div className="w-full flex items-center justify-center">
          {/* <iframe
            title="lottie"
            src="https://embed.lottiefiles.com/animation/56811"
            width="500"
            height="500"
          ></iframe> */}
          <lottie-player
            src="https://assets2.lottiefiles.com/packages/lf20_lzwjunns.json"
            background="transparent"
            speed={1}
            style={{ width: "500px", height: "500px" }}
            loop
            autoPlay
          />
        </div>
      </div>
    </div>
  );
};

export default ILLHero;
