import React, { useRef, useState } from "react";
import contactUs from "../../Assets/partnerSupport.jpg";
import emailjs from "@emailjs/browser";
import CircularProgress from "@mui/material/CircularProgress";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const PartnerContact = () => {
  const [loading, setLoading] = useState(false);

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true);

    if (
      e.target.from_name.value &&
      e.target.from_email.value &&
      e.target.message.value
    ) {
      emailjs
        .sendForm(
          "service_u5omr82",
          "template_m0db0fs",
          form.current,
          "user_DFrWi8VhNS5Avl5xtJ0ja"
        )
        .then(
          (result) => {
            toast("Email Sent, We'll get back to you soon", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            form.current.reset();
            setLoading(false);
            console.log(result.text);
          },
          (error) => {
            console.log(error.text);
          }
        );
    } else {
      setLoading(false);

      toast.error("Please input all the values", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  return (
    <div>
      <ToastContainer />
      <div className="max-w-screen-xl my-10 mb-20 px-8 grid gap-8 grid-cols-1 md:grid-cols-2 md:px-12 lg:px-16 xl:px-32 mx-auto  text-gray-900 rounded-lg">
        <div className="flex flex-col justify-between">
          <div>
            <h2 className="text-4xl lg:text-4xl mb-2 text-red-600 font-semibold leading-tight">
              Contact Us
            </h2>
          </div>
          <div className="mt-2 text-center flex flex-start">
            <img
              src={contactUs}
              class="text-gray-100 mx-auto"
              style={{ height: 400 }}
            />
          </div>
        </div>
        <form ref={form} onSubmit={sendEmail}>
          <div>
            <span className=" text-sm text-gray-600 font-semibold">
              Full Name
            </span>
            <input
              className="w-full bg-gray-200 text-gray-900 mt-2 p-4 rounded-lg focus:outline-none focus:shadow-outline"
              type="text"
              placeholder
              name="from_name"
            />
          </div>
          <div className="mt-4">
            <span className=" text-sm text-gray-600 font-semibold">Email</span>
            <input
              className="w-full bg-gray-200 text-gray-900 mt-2 p-4 rounded-lg focus:outline-none focus:shadow-outline"
              type="text"
              name="from_email"
            />
          </div>
          <div className="mt-4">
            <span className=" text-sm text-gray-600 font-semibold">
              Message
            </span>
            <textarea
              name="message"
              className="w-full h-32 bg-gray-200 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
              defaultValue={""}
            />
          </div>
          <div className="mt-4">
            <button
              type="submit"
              className=" text-sm font-semibold tracking-wide bg-red-600 text-gray-100 p-4 rounded-lg w-full focus:outline-none focus:shadow-outline"
            >
              {loading ? (
                <CircularProgress size={20} style={{ color: "#fff" }} />
              ) : (
                "Send"
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PartnerContact;
