import React from "react";

const ReferralOffer = () => {
  return (
    <div className=" bg-gray-200 rounded-lg shadow-lg md:mx-20 mt-10">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="font-medium text-4xl text-red-600">
          Referral Program
        </div>
        <div className="text-lg my-5">
          Now you can refer your friends & family members and get a{" "}
          <span className="font-bold">50% discount on your bill !!</span>
        </div>
        <div className="text-lg">
          Just refer a new Maxx Fibernet Broadband Connection to your friends &
          family and for every successful connection get a discount of 50% on
          your next bill.
        </div>
        <div className="text-md italic my-5">
          ** In case there are multiple references who have taken a new
          broadband connection, the discount will be adjusted in consecutive
          months depending on the number of references. ** Offer is not
          applicable for customers using half-yearly and yearly broadband plans.
        </div>
        <div className="text-md antialiased">
          To avail this offer a customer must send a mail to
          connect@maxxfibernet.in with:
        </div>
        <div className="list uppercase text-lg my-4 font-medium">
          <div className="list-item">USER ID of the referee</div>
          <div className="list-item">NAME & ADDRESS of the referral</div>
        </div>
        <div className="text-lg mb-4">
          The offer must be claimed within 60 days from activation of referral
          connection, beyond which the offer will lapse.
        </div>
        <div className="text-md antialiased">
          <span className="font-semibold">Note:</span> MAXX FIBERNET reserve the
          right, at any time, without prior notice and without assigning any
          reason whatsoever, to add/alter/modify/change or vary these terms and
          conditions or to replace, wholly or in part, any offer or plan by
          another offer or plan, whether similar to the existing Offer/Plan or
          not, or to extend or withdraw it altogether.
        </div>
      </div>
    </div>
  );
};

export default ReferralOffer;
