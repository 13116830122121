import React from "react";

const PlanTypography = () => {
  return (
    <div className="plan-footer">
      <div className="text-4xl font-bold text-red-600">Maxx Fibernet</div>
      <div className="font-light mt-5 text-base">
        Step into the future of unlimited broadband plans with Maxx Fibernet.
        Experience the high-speed internet on our existing Fiber broadband
        connection across multiple devices.
      </div>
      <div className="font-light mt-5">
        Maxx Fibernet offers you with Fiber optic internet connection
        transforming your daily broadband connection experience with high-speed
        internet. We allow you to enjoy the fastest broadband connection with
        the speed of up to 1 Gbps, which means once you connect broadband, you
        will get faster downloads and less buffering.
      </div>
      <div className="font-light mt-5">
        This FTTH (Fiber to home) technology provides you with fully-dedicated
        Fiber optic cable different internet packages, which connect up to 60
        devices via a professional-grade Wi-Fi router modem. These internet
        packages offer faster HD video streaming, heavy file downloads, and
        uploads.
      </div>
    </div>
  );
};

export default PlanTypography;
