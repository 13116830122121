import React from "react";
import { Link } from "react-router-dom";
import ContactModal from "../Components/ContactModal";

const CallToAction = () => {
  return (
    <div style={{ backgroundColor: "#960000" }}>
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
        <h2 className="text-3xl font-semibold tracking-tight text-white sm:text-4xl">
          <span className="block font-extrabold">Ready to dive in?</span>

          <span
            className="block text-white"
            style={{ color: "#f7f7f7", fontSize: 30 }}
          >
            Get Connected. Stay Connected
          </span>
        </h2>
        <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
          {/* <Link
              to="/plans/fiber"
              className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-red-700 hover:bg-red-700"
            >
              Get started
            </Link> */}
          <ContactModal footer={true} />
        </div>
      </div>
    </div>
  );
};

export default CallToAction;
