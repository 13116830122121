import { data } from "autoprefixer";
import React, { useEffect, useState } from "react";
import Carousel from "react-elastic-carousel";
import CircularProgress from "@mui/material/CircularProgress";
import ContactModal from "./ContactModal";

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 600, itemsToShow: 2, itemsToScroll: 2 },
  { width: 800, itemsToShow: 3 },
  { width: 1200, itemsToShow: 4 },
];

const PlanCard = ({ plansData }) => {
  const [isMobile, setIsMobile] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  console.log(isMobile, window.innerWidth, "Mobile");

  useEffect(() => {
    if (window.innerWidth <= 800) {
      setIsMobile(true);
    }
  });

  setTimeout(() => setIsLoading(false), 400);

  return (
    <>
      {isLoading ? (
        <div className="flex flex-row justify-center items-center mt-10">
          <CircularProgress style={{ color: "#dc2626" }} />
        </div>
      ) : (
        <div className=" max-w-7xl mx-auto px-4">
          {isMobile ? (
            <>
              <div className="flex flex-row overflow-x-scroll plansScroll py-3">
                {plansData?.map((item) => {
                  return (
                    <div
                      style={{ width: 350 }}
                      className=" shadow-lg rounded-lg mr-3 py-2"
                    >
                      <div className="flex flex-row items-center justify-between border-l-4 px-3 border-l-red-600">
                        <div className="flex flex-col">
                          <span className="font-bold">{item?.plan}</span>
                          <span>{`₹${item?.price}/month`}</span>
                        </div>
                        <div>
                          <ContactModal home={true} />
                        </div>
                      </div>
                      <div className="flex flex-col px-2 mt-4 text-sm">
                        <table>
                          <tbody>
                            <tr className="text-sm text-gray-500">
                              <td>
                                <span>{`Data :`}</span>
                              </td>
                              <td>
                                <span className="ml-1">{` ${item?.fup} Internet`}</span>
                              </td>
                            </tr>
                            <tr className="text-sm text-gray-500">
                              <td>
                                <span>{`Speed :`}</span>
                              </td>
                              <td>
                                <span className="ml-1 font-bold">{` ${item?.speed} `}</span>
                              </td>
                            </tr>
                            <tr className="text-sm text-gray-500">
                              <td>
                                <span>High Speed </span>
                              </td>
                              <td>
                                <span className="ml-1">Internet</span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="px-3 mt-4 flex flex-row items-center justify-between">
                        {item?.benefits?.map((ott) => {
                          return (
                            <div className="ott-container mx-1 flex flex-col justify-center items-center">
                              <ott.img color="#e40000" size={30} />
                              <div className="ott-title text-center mt-1 text-xs text-gray-400">
                                {ott.platform}
                              </div>
                            </div>
                          );
                        })}
                        {item?.ott?.map((ott) => {
                          return (
                            <div className="ott-container mx-1 flex flex-col justify-center items-center">
                              <img src={ott?.img} className="w-10" />
                              <div className="ott-title text-center mt-1 text-xs text-gray-400">
                                {ott.platform}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div className="px-3 mt-4 flex flex-row  items-center">
                        {item?.ottRow?.map((ott) => {
                          return (
                            <div className="ott-container mx-1 flex flex-col justify-center items-center">
                              <img src={ott.img} style={{ height: 30 }} />
                              <div className="ott-title text-center mt-1 text-xs text-gray-400">
                                {ott.platform}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          ) : (
            <div className="carousel-wrapper">
              <Carousel breakPoints={breakPoints}>
                {plansData.map((plan) => {
                  return (
                    <div
                      className="card shadow-sm rounded-lg bg-white"
                      style={{ width: "280px" }}
                    >
                      <div className="card-image">
                        <img
                          src={plan?.hero}
                          alt="Basic"
                          className="rounded-md rounded-tl-none rounded-bl-none rounded-br-none inline-block"
                        />
                        <div className="card-content bg-white px-2">
                          <div className="card-title text-xl mx-1 mb-2 mt-2 font-bold">
                            {plan.plan}
                          </div>
                          <div className="card-specs flex justify-between">
                            <div className="card-single-spec mx-1">
                              <div className="plan-fup text-sm">{plan.fup}</div>
                              <div className="plan-fup text-xs text-gray-800">
                                Internet
                              </div>
                            </div>
                            <div className="card-single-spec mx-1">
                              <div className="plan-fup text-sm font-bold">
                                Up to {plan.speed}
                              </div>
                              <div className="plan-fup text-xs text-gray-800">
                                Speed
                              </div>
                            </div>
                            <div className="card-single-spec mx-1">
                              <div className="plan-fup text-sm">99%</div>
                              <div className="plan-fup text-xs text-gray-800">
                                Uptime*
                              </div>
                            </div>
                          </div>
                          <div className="card-benefits text-xs mx-1  text-gray-400 my-3">
                            Included Benefits
                          </div>
                          <div className="card-ott-icons flex flex-row justify-evenly items-center">
                            {plan?.benefits?.map((ott) => {
                              return (
                                <div className="ott-container mx-1 flex flex-col justify-center items-center">
                                  <ott.img color="#e40000" size={30} />
                                  <div className="ott-title text-center mt-1 text-xs text-gray-400">
                                    {ott.platform}
                                  </div>
                                </div>
                              );
                            })}
                            {plan?.ott?.map((ott) => {
                              return (
                                <div className="ott-container mx-1 flex flex-col justify-center items-center">
                                  <img src={ott?.img} className="w-10" />
                                  <div className="ott-title text-center mt-1 text-xs text-gray-400">
                                    {ott.platform}
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                          <div className="px-3 mt-4 flex flex-row  items-center">
                            {plan?.ottRow?.map((ott) => {
                              return (
                                <div className="ott-container mx-1 flex flex-col justify-center items-center">
                                  <img src={ott.img} style={{ height: 30 }} />
                                  <div className="ott-title text-center mt-1 text-xs text-gray-400">
                                    {ott.platform}
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>

                        <div className="card-footer px-2 mt-6 mb-1 pb-2 flex items-center justify-between ">
                          <div className="rental">
                            <div className="text-xs mx-1 text-gray-500">
                              Monthly Rental
                            </div>
                            <div className="plan-price mx-1 text-xl antialiased">
                              ₹{plan.price}
                            </div>
                          </div>

                          <div className="buy-now-button">
                            <button className="uppercase text-sm px-5 py-2 bg-red-600 rounded text-white hover:bg-red-800 transition ease-in-out delay-150">
                              Buy Now
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Carousel>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default PlanCard;
