import React from "react";
import HeaderNav from "../../Components/HeaderNav";
import OfferHero from "../../Components/OfferHero";
import OfferFaq from "../../Components/OfferFaq";
import OffersTnC from "../../Components/OffersTnC";
import Footer from "../../Components/Footer";
import ReferralOffer from "./components/ReferralOffer";

const Offers = () => {
  return (
    <div>
      <HeaderNav />
      <OfferHero />
      <ReferralOffer />

      {/* <OfferFaq />
      <OffersTnC /> */}
      <Footer />
    </div>
  );
};

export default Offers;
