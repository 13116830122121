import React from "react";
import HeaderNav from "../../Components/HeaderNav";
import PartnerContact from "../PartnerProgram/PartnerContact";
import Footer from "../../Components/Footer";

const Contact = () => {
  return (
    <div>
      <HeaderNav />
      <div className="mb-5">
        <PartnerContact />
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
