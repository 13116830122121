import { Fragment, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import {
  BookmarkAltIcon,
  HomeIcon,
  ChartBarIcon,
  CursorClickIcon,
  MenuIcon,
  PhoneIcon,
  PlayIcon,
  RefreshIcon,
  ShieldCheckIcon,
  SupportIcon,
  ViewGridIcon,
  DesktopComputerIcon,
  XIcon,
  WifiIcon,
  GlobeAltIcon,
} from "@heroicons/react/outline";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { SiSpeedtest } from "react-icons/si";
import { Link } from "react-router-dom";
import Logo from "../Assets/MaxxFibernetLogo.svg";
import LogoWP from "../Assets/Logo-Worldphone-300x114.png";
import ContactModal from "./ContactModal";
const solutions = [
  {
    name: "Fiber Optical",
    description:
      " Connect with FTTH Technology with blazing fast Internet upto 1Gbps.",
    href: "/plans/fiber",
    icon: ChartBarIcon,
  },
  {
    name: "AirFiber (Wi-Fi Internet)",
    description:
      "Connecting Rural Areas with cutting edge Wireless Technology.",
    href: "/plans/wireless",
    icon: WifiIcon,
  },
  {
    name: "OTT Packages",
    description:
      "Enjoy all your favourite OTT and TV Channels at one place one & one price",
    href: "/plans/ott",
    icon: DesktopComputerIcon,
  },
];
const callsToAction = [
  {
    name: "Speed Test",
    href: "https://worldmaxx.speedtestcustom.com/",
    tag: "For Existing Users",
    icon: SiSpeedtest,
  },
  { name: "Contact Us", href: "tel:+917310444123", icon: PhoneIcon },
];
const resources = [
  {
    name: "Partner Program",
    description: "Partner Program for Franchise and Sales Agents",
    href: "/partner",
    icon: BookmarkAltIcon,
  },
  {
    name: "Leased Line (ILL)",
    description: "Best 1:1 DIA ILL curated for your business.",
    href: "/ill",
    icon: SupportIcon,
  },

  {
    name: "Home Security Solutions",
    description: "Get secured with our best in class home security solutions.",
    href: "#",
    icon: HomeIcon,
  },
  // {
  //   name: "VPN (Virtual Private Network)",
  //   description: "Protect yourself from online attacks with our VPN service.",
  //   href: "#",
  //   icon: ShieldCheckIcon,
  // },
  // {
  //   name: "Contact Us",
  //   href: "tel:+917310444123",
  //   description: "Get Support ASAP!",
  //   icon: PhoneIcon,
  // },
];
const recentPosts = [
  { id: 1, name: "Boost your conversion rate", href: "#" },
  {
    id: 2,
    name: "How to use search engine optimization to drive traffic to your site",
    href: "#",
  },
  { id: 3, name: "Improve your customer experience", href: "#" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function HeaderNav() {
  const [hover, setHover] = useState(false);

  return (
    <Popover className="relative bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6">
        <div className="flex justify-between items-center border-b-2 border-gray-100 py-3 md:justify-start md:space-x-10">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <Link to="/" className="flex items-center">
              <span className="sr-only">Workflow</span>
              <img
                className="h-10 w-auto sm:h-10 mx-2 pt-2"
                src={LogoWP}
                alt=""
              />
              <img className="h-10 w-auto sm:h-10" src={Logo} alt="" />
            </Link>
          </div>
          <div className="-mr-2 -my-2 md:hidden">
            <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100">
              <span className="sr-only">Open menu</span>
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>

          <Popover.Group as="nav" className="hidden md:flex space-x-10">
            <Link
              to="/"
              className="text-base font-medium text-gray-500 hover:text-red-600"
            >
              Home
            </Link>
            <Popover
              className="relative"
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
            >
              {({ hover }) => (
                <>
                  <Popover.Button
                    className={classNames(
                      hover ? "text-red-600" : "text-gray-500",
                      "group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-red-600"
                    )}
                  >
                    <span>Our Packages</span>
                    <ChevronDownIcon
                      className={classNames(
                        hover ? "text-red-600" : "text-gray-400",
                        "ml-2 h-5 w-5 group-hover:text-red-600"
                      )}
                      aria-hidden="true"
                    />
                  </Popover.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="absolute z-10 -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2">
                      <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                        <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                          {solutions.map((item) => (
                            <Link
                              key={item.name}
                              to={item.href}
                              className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                            >
                              <item.icon
                                className="flex-shrink-0 h-6 w-6 text-red-600"
                                aria-hidden="true"
                              />
                              <div className="ml-4">
                                <p className="text-base font-medium text-gray-900">
                                  {item.name}
                                </p>
                                <p className="mt-1 text-sm text-gray-500">
                                  {item.description}
                                </p>
                              </div>
                            </Link>
                          ))}
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>
            {/* <Popover className="relative">
              {({ open }) => (
                <>
                  <Popover.Button
                    className={classNames(
                      open ? "text-red-600" : "text-gray-500",
                      "group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-red-600"
                    )}
                  >
                    <span>Services</span>
                    <ChevronDownIcon
                      className={classNames(
                        open ? "text-red-600" : "text-gray-400",
                        "ml-2 h-5 w-5 group-hover:text-red-500"
                      )}
                      aria-hidden="true"
                    />
                  </Popover.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="absolute z-10 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-md sm:px-0">
                      <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                        <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                          {resources.map((item) => (
                            <Link
                              key={item.name}
                              to={item.href}
                              className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                            >
                              <item.icon
                                className="flex-shrink-0 h-6 w-6 text-red-600"
                                aria-hidden="true"
                              />
                              <div className="ml-4">
                                <p className="text-base font-medium text-gray-900">
                                  {item.name}
                                </p>
                                <p className="mt-1 text-sm text-gray-500">
                                  {item.description}
                                </p>
                              </div>
                            </Link>
                          ))}
                          <div className="space-y-6 sm:flex sm:space-y-0 sm:space-x-10 sm:px-8">
                            {callsToAction.map((item) => (
                              <div key={item.name} className="flow-root">
                                <a
                                  href={item.href}
                                  target="_blank"
                                  className="-m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-100"
                                >
                                  <item.icon
                                    className="flex-shrink-0 h-6 w-6 text-gray-400"
                                    aria-hidden="true"
                                  />
                                  <span className="ml-3">{item.name}</span>
                                </a>
                                <p className="ml-10 text-xs">{item?.tag}</p>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover> */}
            {/* <Link
              to="/offers"
              className="text-base font-medium text-gray-500 hover:text-red-600"
            >
              Offers
            </Link> */}
          </Popover.Group>
          <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
            <a
              href="https://portal.maxxfibernet.in/"
              className="whitespace-nowrap text-base font-medium text-red-600 hover:text-red-700"
            >
              Pay Bill
            </a>
            {/* <a
              href="#"
              className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-red-600 hover:bg-red-700"
            >
              Get New Connection
            </a> */}
            <ContactModal header={true} />
          </div>
        </div>
      </div>

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          className="z-50 absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
        >
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
            <div className="pt-5 pb-6 px-5">
              <div className="flex items-center justify-between">
                <Link to="/">
                  <img className="h-10 w-auto" src={Logo} alt="Workflow" />
                </Link>
                <div className="-mr-2">
                  <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100">
                    <span className="sr-only">Close menu</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="mt-6">
                <nav className="grid gap-y-8">
                  {solutions.map((item) => (
                    <Link
                      key={item.name}
                      to={item.href}
                      className="-m-3 p-3 flex flex-col items-start rounded-md hover:bg-gray-50"
                    >
                      <item.icon
                        className="flex-shrink-0 h-6 w-6 text-red-600"
                        aria-hidden="true"
                      />
                      <span className=" text-base font-medium text-gray-900">
                        {item.name}
                      </span>
                      <p className="mt-1 text-sm text-gray-500">
                        {item.description}
                      </p>
                    </Link>
                  ))}
                </nav>
              </div>
            </div>
            <div className="py-6 px-5 space-y-6">
              <div className="grid grid-cols-2 gap-y-4 gap-x-8">
                {resources.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className="text-base font-medium text-gray-900 hover:text-gray-700"
                  >
                    {item.name}
                  </a>
                ))}
              </div>
              <div>
                {/* <a
                  href="#"
                  className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-red-600 hover:bg-red-700"
                >
                  Sign up
                </a> */}
                <p className="mt-6 text-center text-base font-medium text-gray-500">
                  Pay Bill?{" "}
                  <a
                    href="http://portal.maxxfibernet.in"
                    className="text-red-600 hover:text-red-500"
                  >
                    Login
                  </a>
                </p>
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
