import * as React from "react";
import Entertainment from "../../../Assets/entertainment.jpg";
import EpicOn from "../../../Assets/epicOn.png";
import PlayBox from "../../../Assets/playBox.png";
import Prime from "../../../Assets/prime.png";
import Shemaroo from "../../../Assets/shemaroo.png";
import SonyLiv from "../../../Assets/sonyLiv.png";
import Voot from "../../../Assets/voot.png";
import Zee5 from "../../../Assets/zee5.png";
import PlanCard from "../../../Components/PlanCard";

// const plansDataBase = [
//   {
//     hero: Basic,
//     plan: "Silver with Base OTT",
//     fup: "Unlimited",
//     speed: "50Mbps",
//     price: "1099",
//     ott: [
//       {
//         platform: "Epic-on",
//         img: EpicOn,
//       },
//       {
//         platform: "Shemaroo",
//         img: Shemaroo,
//       },
//       {
//         platform: "Playbox Tv",
//         img: PlayBox,
//       },
//     ],
//   },
//   {
//     hero: Basic,
//     plan: "Gold with Base OTT",
//     fup: "Unlimited",
//     speed: "100Mbps",
//     price: "1599",
//     ott: [
//       {
//         platform: "Epic-on",
//         img: EpicOn,
//       },
//       {
//         platform: "Shemaroo",
//         img: Shemaroo,
//       },
//       {
//         platform: "Playbox Tv",
//         img: PlayBox,
//       },
//     ],
//   },
//   {
//     hero: Basic,
//     plan: "Platinum with Base OTT",
//     fup: "Unlimited",
//     speed: "200Mbps",
//     price: "2499",
//     ott: [
//       {
//         platform: "Epic-on",
//         img: EpicOn,
//       },
//       {
//         platform: "Shemaroo",
//         img: Shemaroo,
//       },
//       {
//         platform: "Playbox Tv",
//         img: PlayBox,
//       },
//     ],
//   },
//   {
//     hero: Basic,
//     plan: "Diamond with Base OTT",
//     fup: "Unlimited",
//     speed: "300Mbps",
//     price: "3499",
//     ott: [
//       {
//         platform: "Epic-on",
//         img: EpicOn,
//       },
//       {
//         platform: "Shemaroo",
//         img: Shemaroo,
//       },
//       {
//         platform: "Playbox Tv",
//         img: PlayBox,
//       },
//     ],
//   },
// ];

// const plansDataPlus = [
//   {
//     hero: Silver,
//     plan: "Silver with Plus OTT",
//     fup: "Unlimited",
//     speed: "50Mbps",
//     price: "1199",
//     ott: [
//       {
//         platform: "Epic-on",
//         img: EpicOn,
//       },
//       {
//         platform: "Shemaroo",
//         img: Shemaroo,
//       },
//       {
//         platform: "Playbox Tv",
//         img: PlayBox,
//       },
//     ],
//     ottRow: [
//       {
//         platform: "Voot",
//         img: Voot,
//       },
//       {
//         platform: "Zee5",
//         img: Zee5,
//       },
//     ],
//   },
//   {
//     hero: Silver,
//     plan: "Gold with Plus OTT",
//     fup: "Unlimited",
//     speed: "100Mbps",
//     price: "1749",
//     ott: [
//       {
//         platform: "Epic-on",
//         img: EpicOn,
//       },
//       {
//         platform: "Shemaroo",
//         img: Shemaroo,
//       },
//       {
//         platform: "Playbox Tv",
//         img: PlayBox,
//       },
//     ],
//     ottRow: [
//       {
//         platform: "Voot",
//         img: Voot,
//       },
//       {
//         platform: "Zee5",
//         img: Zee5,
//       },
//     ],
//   },
//   {
//     hero: Silver,
//     plan: "Platinum with Plus OTT",
//     fup: "Unlimited",
//     speed: "200Mbps",
//     price: "2699",
//     ott: [
//       {
//         platform: "Epic-on",
//         img: EpicOn,
//       },
//       {
//         platform: "Shemaroo",
//         img: Shemaroo,
//       },
//       {
//         platform: "Playbox Tv",
//         img: PlayBox,
//       },
//     ],
//     ottRow: [
//       {
//         platform: "Voot",
//         img: Voot,
//       },
//       {
//         platform: "Zee5",
//         img: Zee5,
//       },
//     ],
//   },
//   {
//     hero: Silver,
//     plan: "Diamond with Plus OTT",
//     fup: "Unlimited",
//     speed: "300Mbps",
//     price: "3599",
//     ott: [
//       {
//         platform: "Epic-on",
//         img: EpicOn,
//       },
//       {
//         platform: "Shemaroo",
//         img: Shemaroo,
//       },
//       {
//         platform: "Playbox Tv",
//         img: PlayBox,
//       },
//     ],
//     ottRow: [
//       {
//         platform: "Voot",
//         img: Voot,
//       },
//       {
//         platform: "Zee5",
//         img: Zee5,
//       },
//     ],
//   },
// ];
// const plansDataPremium = [
//   {
//     hero: Work,
//     plan: "Premium",
//     fup: "Unlimited",
//     speed: "50Mbps",
//     price: "1299",
//     ott: [
//       {
//         platform: "Epic-on",
//         img: EpicOn,
//       },
//       {
//         platform: "Shemaroo",
//         img: Shemaroo,
//       },
//       {
//         platform: "Playbox Tv",
//         img: PlayBox,
//       },
//     ],
//     ottRow: [
//       {
//         platform: "Voot",
//         img: Voot,
//       },
//       {
//         platform: "Zee5",
//         img: Zee5,
//       },
//       {
//         platform: "Sony LIV",
//         img: SonyLiv,
//       },
//     ],
//   },
//   {
//     hero: Work,
//     plan: "Premium",
//     fup: "Unlimited",
//     speed: "50Mbps",
//     price: "1299",
//     ott: [
//       {
//         platform: "Epic-on",
//         img: EpicOn,
//       },
//       {
//         platform: "Shemaroo",
//         img: Shemaroo,
//       },
//       {
//         platform: "Playbox Tv",
//         img: PlayBox,
//       },
//     ],
//     ottRow: [
//       {
//         platform: "Voot",
//         img: Voot,
//       },
//       {
//         platform: "Zee5",
//         img: Zee5,
//       },
//       {
//         platform: "Sony LIV",
//         img: SonyLiv,
//       },
//     ],
//   },
//   {
//     hero: Work,
//     plan: "Premium",
//     fup: "Unlimited",
//     speed: "50Mbps",
//     price: "1299",
//     ott: [
//       {
//         platform: "Epic-on",
//         img: EpicOn,
//       },
//       {
//         platform: "Shemaroo",
//         img: Shemaroo,
//       },
//       {
//         platform: "Playbox Tv",
//         img: PlayBox,
//       },
//     ],
//     ottRow: [
//       {
//         platform: "Voot",
//         img: Voot,
//       },
//       {
//         platform: "Zee5",
//         img: Zee5,
//       },
//       {
//         platform: "Sony LIV",
//         img: SonyLiv,
//       },
//     ],
//   },
//   {
//     hero: Work,
//     plan: "Premium",
//     fup: "Unlimited",
//     speed: "50Mbps",
//     price: "1299",
//     ott: [
//       {
//         platform: "Epic-on",
//         img: EpicOn,
//       },
//       {
//         platform: "Shemaroo",
//         img: Shemaroo,
//       },
//       {
//         platform: "Playbox Tv",
//         img: PlayBox,
//       },
//     ],
//     ottRow: [
//       {
//         platform: "Voot",
//         img: Voot,
//       },
//       {
//         platform: "Zee5",
//         img: Zee5,
//       },
//       {
//         platform: "Sony LIV",
//         img: SonyLiv,
//       },
//     ],
//   },
//   {
//     hero: Work,
//     plan: "Premium",
//     fup: "Unlimited",
//     speed: "50Mbps",
//     price: "1299",
//     ott: [
//       {
//         platform: "Epic-on",
//         img: EpicOn,
//       },
//       {
//         platform: "Shemaroo",
//         img: Shemaroo,
//       },
//       {
//         platform: "Playbox Tv",
//         img: PlayBox,
//       },
//     ],
//     ottRow: [
//       {
//         platform: "Voot",
//         img: Voot,
//       },
//       {
//         platform: "Zee5",
//         img: Zee5,
//       },
//       {
//         platform: "Sony LIV",
//         img: SonyLiv,
//       },
//     ],
//   },
// ];
const plansDataPlatinum = [
  {
    hero: Entertainment,
    plan: "Base",
    fup: "Unlimited",
    speed: "100Mbps",
    price: "949",
    ott: [
      {
        platform: "Epic-on",
        img: EpicOn,
      },
      {
        platform: "Shemaroo",
        img: Shemaroo,
      },
      {
        platform: "Playbox Tv",
        img: PlayBox,
      },
    ],
  },
  {
    hero: Entertainment,
    plan: "Plus",
    fup: "Unlimited",
    speed: "100Mbps",
    price: "1049",
    ott: [
      {
        platform: "Epic-on",
        img: EpicOn,
      },
      {
        platform: "Shemaroo",
        img: Shemaroo,
      },
      {
        platform: "Playbox Tv",
        img: PlayBox,
      },
    ],
    ottRow: [
      {
        platform: "Voot",
        img: Voot,
      },
      {
        platform: "Zee5",
        img: Zee5,
      },
    ],
  },
  {
    hero: Entertainment,
    plan: "Premium",
    fup: "Unlimited",
    speed: "100Mbps",
    price: "1149",
    ott: [
      {
        platform: "Epic-on",
        img: EpicOn,
      },
      {
        platform: "Shemaroo",
        img: Shemaroo,
      },
      {
        platform: "Playbox Tv",
        img: PlayBox,
      },
    ],
    ottRow: [
      {
        platform: "Voot",
        img: Voot,
      },
      {
        platform: "Zee5",
        img: Zee5,
      },
      {
        platform: "Sony LIV",
        img: SonyLiv,
      },
    ],
  },
  {
    hero: Entertainment,
    plan: "Platinum",
    fup: "Unlimited",
    speed: "100Mbps",
    price: "1249",
    ott: [
      {
        platform: "Epic-on",
        img: EpicOn,
      },
      {
        platform: "Shemaroo",
        img: Shemaroo,
      },
      {
        platform: "Playbox Tv",
        img: PlayBox,
      },
    ],
    ottRow: [
      {
        platform: "Voot",
        img: Voot,
      },
      {
        platform: "Zee5",
        img: Zee5,
      },
      {
        platform: "Sony LIV",
        img: SonyLiv,
      },
      {
        platform: "Amazon Prime",
        img: Prime,
      },
    ],
  },
];

export default function OttTabs() {
  return <PlanCard plansData={plansDataPlatinum} />;
}
