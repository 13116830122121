import React from "react";
import EntertainmentBannerImg from "../Assets/removedBg2.jpg";
import SmallerEntertainmentCards from "./SmallerEntertainmentCards";
import Logo from "../Assets/MaxxFibernetLogo.svg";
const EntertainmentBanner = () => {
  return (
    <>
      <div className="flex flex-col items-center py-6 relative mb-5">
        <span>
          <img src={Logo} alt="logo" style={{ height: 50 }} />
        </span>
        <span
          className="xl:text-3xl lg:text-2xl md:text-xl sm:text-xl my-2 font-medium px-2 text-center"
          style={{ color: "#3b3a39" }}
        >
          UNLOCK THE WORLD OF ENTERTAINMENT
        </span>
        <img
          src={EntertainmentBannerImg}
          alt=""
          className="xl:w-1/2 lg:mb-20"
        />
        <div className="lg:absolute bottom-0 flex ml-5">
          <SmallerEntertainmentCards />
        </div>
      </div>
    </>
  );
};

export default EntertainmentBanner;
