import React from "react";
import FiberOptical from "../Assets/fiberOptical.jpg";
import AirFiber from "../Assets/airFiber.jpg";
import OTT from "../Assets/man-watching-streaming-service-his-tv.jpg";
import { Link } from "react-router-dom";

const entertainment = [
  {
    title: "Fibre Optical",
    img: FiberOptical,
    path: "/plans/fiber",
  },
  {
    title: " AirFiber",
    img: AirFiber,
    path: "/plans/wireless",
  },
  {
    title: "OTT Packages",
    img: OTT,
    path: "/plans/ott",
  },
];

const SmallerEntertainmentCards = () => {
  return (
    <div className="flex flex-col z-50 w-full md:flex-row">
      {entertainment.map((item) => {
        return (
          <div
            className="shadow-lg bg-white mt-10 rounded-xl px-3 py-2 mr-4 flex flex-col items-center flex-1"
            style={{ width: 300 }}
          >
            <h3
              style={{ color: "#3b3a39" }}
              className="text-sm flex mb-2 font-semibold"
            >
              {item?.title}
            </h3>
            <img
              src={item?.img}
              alt={item?.title}
              style={{ height: 120, width: 180, borderRadius: 12 }}
            />
            <Link to={item?.path}>
              <a
                href="#"
                className="w-full mt-5 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700"
              >
                Read More
              </a>
            </Link>
          </div>
        );
      })}
    </div>
  );
};

export default SmallerEntertainmentCards;
