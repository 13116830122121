import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import emailjs from "@emailjs/browser";
import CircularProgress from "@mui/material/CircularProgress";
import { toast, ToastContainer } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 550,
  minWidth: 300,
  boxShadow: 24,
  borderRadius: 2,
  p: "10px 20px",
};

export default function ContactModal({ footer, offer, home, header }) {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [other, setOther] = React.useState("");
  const form = React.useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true);

    emailjs
      .sendForm(
        "service_u5omr82",
        "template_54vuucm",
        form.current,
        "user_DFrWi8VhNS5Avl5xtJ0ja"
      )
      .then(
        (result) => {
          toast("Email Sent, We'll get back to you soon", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          form.current.reset();
          setLoading(false);
          console.log(result.text);
        },
        (error) => {
          toast.error("Email not sent, please check all the fields", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      );
  };
  return (
    <div>
      <ToastContainer />

      {offer && (
        <a
          onClick={handleOpen}
          href="#"
          className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-red-600 "
        >
          Get New Connection
        </a>
      )}
      {footer && (
        <a
          onClick={handleOpen}
          href="#"
          className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-red-600 hover:bg-red-700"
        >
          Get New Connection
        </a>
      )}
      {home && (
        <a
          onClick={handleOpen}
          href="#"
          className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-red-600 hover:bg-red-700"
        >
          Get Now
        </a>
      )}
      {header && (
        <a
          onClick={handleOpen}
          href="#"
          className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-red-600 hover:bg-red-700"
        >
          Get New Connection
        </a>
      )}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="getForm">
          <div className="flex flex-col sm:flex-row items-center">
            <h2
              className="font-semibold mb-4 text-xl mr-auto"
              style={{ fontFamily: "Inter" }}
            >
              We'd be so glad to hear from you..
            </h2>
            <div className="w-full sm:w-auto sm:ml-auto mt-3 sm:mt-0" />
          </div>
          <form ref={form} onSubmit={sendEmail}>
            <div className="md:flex flex-row md:space-x-4 w-full text-xs">
              <div className="mb-3 space-y-2 w-full text-xs">
                <label className="font-semibold text-gray-900 py-2">
                  Name*
                </label>
                <input
                  placeholder="Name"
                  className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded-md h-10 px-4"
                  required="required"
                  type="text"
                  name="from_name"
                />
                <p className="text-red text-xs hidden">
                  Please fill out this field.
                </p>
              </div>
              <div className="mb-3 space-y-2 w-full text-xs">
                <label className="font-semibold text-gray-900 py-2">
                  E-Mail*
                </label>
                <input
                  placeholder="Email ID"
                  className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded-md h-10 px-4"
                  required="required"
                  type="text"
                  name="from_email"
                />
                <p className="text-red text-xs hidden">
                  Please fill out this field.
                </p>
              </div>
            </div>

            <div className="md:flex md:flex-row md:space-x-4 w-full text-xs">
              <div className="w-full flex flex-col mb-3">
                <label className="font-semibold text-gray-900 py-2">
                  Phone Number*
                </label>
                <input
                  placeholder="Phone Number"
                  className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded-md h-10 px-4"
                  type="number"
                  name="from_mobile"
                />
              </div>
            </div>
            <div className="md:flex md:flex-row md:space-x-4 w-full text-xs">
              <div className="w-full flex flex-col mb-3">
                <label className="font-semibold text-gray-900 py-2">
                  Address
                </label>
                <input
                  placeholder="Address"
                  className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded-md h-10 px-4"
                  type="text"
                  name="from_address"
                />
              </div>
            </div>
            <div className="w-full flex flex-col mb-3 text-xs">
              <label className="font-semibold text-gray-900 py-2">
                Location*
              </label>
              <select
                className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded-md h-10 px-4 md:w-full "
                required="required"
                name="from_city"
                onChange={(e) => setOther(e.target.value)}
                value={other}
              >
                {/* <option>Akbarpur</option>
                <option>Jalalpur</option> */}
                <option>Other</option>
              </select>
              <p className="text-sm text-red-500 hidden mt-3" id="error">
                Please fill out this field.
              </p>
            </div>
            {other == "Other" && (
              <div className="md:flex md:flex-row md:space-x-4 w-full text-xs">
                <div className="w-full flex flex-col mb-3">
                  <label className="font-semibold text-gray-900 py-2">
                    Specify Area
                  </label>
                  <input
                    placeholder="Specify Area"
                    className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded-md h-10 px-4"
                    type="text"
                    name="from_area"
                  />
                </div>
              </div>
            )}
            <div className="mt-5 mb-3 text-right md:space-x-3 md:block flex flex-col-reverse">
              <button
                className="mb-2 md:mb-0 bg-white px-5 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-900 rounded-md hover:shadow-md hover:bg-gray-100"
                onClick={handleClose}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="mb-2 md:mb-0 bg-red-600 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-md hover:shadow-md hover:bg-red-500"
              >
                Send
              </button>
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );
}
