import React from "react";
import Footer from "../../Components/Footer";
import HeaderNav from "../../Components/HeaderNav";
import ILLHero from "./components/ILLHero";

const LeasedLine = () => {
  return (
    <>
      <HeaderNav />
      <div className="m-10 max-w-7xl mx-auto px-4 sm:px-6">
        <ILLHero />
        {/* <div className="leased-line-content mt-10 italic text-2xl font-bold">
          “No matter if you are a small businessman, an entrepreneur, a MSME,
          SME or a large corporation we have carefully analysed your needs and
          come up with plans that adequately fit into your budgets.”
        </div> */}
      </div>
      <Footer />
    </>
  );
};

export default LeasedLine;
